export const environment = {
  production: true, 
  backEndApiURL: 'https://soft-api.dtzsoft.com/',
  reportURL: 'https://soft-report.dtzsoft.com/',
  facebookAuth: {
    appId: "712492722824287",
    redirect: "https://soft.dtzsoft.com/auth/facebook"
  },
  googleAuth: {
    clientId: "168909620471-rsmoplombpf3ji3f041c9jp21pgv901o.apps.googleusercontent.com",
    redirect: "https://soft.dtzsoft.com/auth/google"
  }
};