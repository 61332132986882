import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit} from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomerChangeComponent } from './shared/customer/customer.component';
import { DataService } from '~/app/core/data.service';
import {Cache} from '~/app/core/lib/cache';
import { AuthService } from '~/app/core/services/auth/auth.service';
import * as signalR from '@microsoft/signalr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  @ViewChild(CustomerChangeComponent) customerChangeModal;

  title = 'DTZSoft';
  isCollapsed = false;
  showSidebar: boolean = false;
  showNavbar: boolean = false;
  showFooter: boolean = false;
  isLoading: boolean;
  showSeting = false;
  showLoading = false;

  constructor(
      private router: Router, 
      public translate: TranslateService,
      private ref: ChangeDetectorRef,
      private dataService: DataService,
      public authService: AuthService,
     ) {
      this.translate.addLangs(['en', 'vi']);
      this.translate.setDefaultLang('vi');
      //this.translate.use("vi");
      // let langCode = localStorage.getItem("lang");
      // if(!langCode)
      // {
      //   this.translate.use("vi");
      // }
  }

  ngOnInit() {
    // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
    this.showSidebar = false;
    this.showNavbar = false;
    this.showFooter = false;
    this.showSeting = false;
    document.querySelector('.main-container .main-panel').classList.remove('main-panel-full');
    document.querySelector('.main-container .main-panel').classList.add('main-panel-full');
    document.querySelector('.main-container .main-panel .content').classList.remove('content-full');
    document.querySelector('.main-container .main-panel .content').classList.add('content-full');
    
    this.router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        if(event['url'] == '/' || (event['url'].indexOf('auth') >= 0) || (event['url'].indexOf('error') >= 0) || (event['url'].indexOf('404') >= 0) || (event['url'].indexOf('error-pages/500') >= 0)|| (event['url'].indexOf('404-not-found') >= 0)) {        
          this.showSidebar = false;
          this.showNavbar = false;
          this.showFooter = false;
          this.showSeting = false;
          document.querySelector('.main-container .main-panel').classList.remove('main-panel-full');
          document.querySelector('.main-container .main-panel').classList.add('main-panel-full');
          document.querySelector('.main-container .main-panel .content').classList.remove('content-full');
          document.querySelector('.main-container .main-panel .content').classList.add('content-full');
        }
        else
        {
          this.showSidebar = true;
          this.showNavbar = true;
          this.showFooter = true;
          this.showSeting = true;
          document.querySelector('.main-container .main-panel').classList.add('main-panel-full');
          document.querySelector('.main-container .main-panel').classList.remove('main-panel-full');
          document.querySelector('.main-container .main-panel .content').classList.add('content-full');
          document.querySelector('.main-container .main-panel .content').classList.remove('content-full');
          
          // this.custId = Cache.getCache("custId");
          // if(!this.custId)
          // {
          //   setTimeout(() => { 
          //     this.customerChangeModal.open();
          //   });
          // }
          //console.log('IDKhoaPhong', Cache.getCache("IDKhoaPhong"));
          if(Cache.getCache("UserName") && 
          (!Cache.getCache("IDKhachHang") || 
          ((event['url'].indexOf('khamBenh/NhanBenh') >= 0
            || event['url'].indexOf('khamBenh/LapToaThuoc') >= 0
            || event['url'].indexOf('hoaDon/PhatThuoc') >= 0
            || event['url'].indexOf('hoaDon/PhieuThu') >= 0) 
            && (!Cache.getCache("IDCaLamViec") || Cache.getCache("IDCaLamViec") == null 
              //|| !Cache.getCache("IDKhoaPhong") || Cache.getCache("IDKhoaPhong") == null)))
              ))))
          {
            setTimeout(() => { 
              this.customerChangeModal.open(true);
            });
          }
        }
        this.ref.detectChanges();
      }
    });
    // Spinner for lazyload modules
    this.router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
          this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
          this.isLoading = false;
      }
    });
    // Scroll to top after route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
    this.dataService.currentMessage.subscribe(message => this.onReceiveCall(message));
    
    if(Cache.getCache("UserName"))
    {
      this.getAccount();
    }
    else {
      this.router.navigate(['auth/login'], {});
    }

    this.initiateSignalrConnection();
  }
  
  getAccount()
  {
    this.authService.account()
    .subscribe((res: any) => {
      if(res.Code == 1)
      { 
        Cache.cache("HoTen", res.Data.HoTen);
        
        //Thông báo thành công
        res.Data.type = "changeProfile";
        this.dataService.changeMessage(res.Data);
      }
    }, error => {
      console.log(error)
    });
  }

  overlaySidebar()
  {
    var sidebar = document.getElementById('sidebar') as HTMLElement;
    var overlay = document.getElementById('overlay-common') as HTMLElement;
    sidebar.classList.remove("show");
    overlay.classList.remove("show");
  }

  clickCollapsed($event)
  {
    this.isCollapsed = $event;
  }

  onReceiveCall(data: any) {
    if(data.type == "changeCustomer")
    {
      this.customerChangeModal.open();
    }
    if(data.type == "closeChangeCustomer")
    {
      this.customerChangeModal.close();
    }
  }
  
  public async initiateSignalrConnection(): Promise<void> {
    try {
      this.authService.connectionHub = new signalR.HubConnectionBuilder()
        .withUrl(this.authService.hubUrl)
        .withAutomaticReconnect()
        .build();

      await this.authService.connectionHub.start();
      this.showLoading = false;
      console.log(`SignalR connection success! connectionId: ${this.authService.connectionHub.connectionId}`);

      this.authService.connectionHub.on("BroadcastMessage", (data) => {  
        let IDKhachHang = Cache.getCache("IDKhachHang");
        if(data && data.IDKhachHang && data.IDKhachHang == IDKhachHang){
          this.dataService.changeHub(data);
        }
      });
      this.authService.connectionHub.onreconnecting((error: any) => {
        this.showLoading = true;
      });
      this.authService.connectionHub.onreconnected(() => {
        this.showLoading = false;
      });
    }
    catch (error) {
      console.log(`SignalR connection error: ${error}`);
    }
  }
}
