import { Component, OnInit, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import {TranslateService } from '@ngx-translate/core';
import {Router, ActivatedRoute} from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { en_US, vi_VN, NzI18nService } from 'ng-zorro-antd/i18n';
import { Cache} from '../../core/lib/cache';
import { KhachHangService } from '~/app/core/services/heThong/khachHang.service';
import { AuthService } from '~/app/core/services/auth/auth.service';
import { DataService } from '~/app/core/data.service';
import * as utils from "~/app/core/utils";
const ITEMS_PER_PAGE = 10;
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  
  @Output() onClick = new EventEmitter<any>();
 
  title: string;
  subTitle: string;
  modalType: string;

  public iconOnlyToggled = true;
  public sidebarToggled = true;
  public barToggled = true;
  
  userFullname = Cache.getCache("UserName");
  caLamViec = Cache.getCache("CaLamViec");
  khoaPhong = Cache.getCache("KhoaPhong");
  username: string = Cache.getCache("UserName");
  avatar: string = "assets/uploads/avatar-default.png";
  messageCount: number = 0;
  notifiCount: number = 0;
  custName = null;
  messagerCount: number = 0;

  isShowNotifi: boolean = false;

  currentPage: number = 1;
  pageSizes: number[] = [];
  dataMessagers = [];
  elem;
  zoom = false;
  
  constructor(
    config: NgbDropdownConfig, 
    public translate: TranslateService,  
    public routerService: Router, 
    private route: ActivatedRoute,
    private modal: NzModalService,
    public khachHangService: KhachHangService,
    public authService: AuthService,
    private dataService: DataService,
    private i18n: NzI18nService,
    @Inject(DOCUMENT) private document: any
    ) {
    config.placement = 'bottom-right';
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this.getDataCus();
    this.dataService.currentMessage.subscribe(message => this.onReceiveCall(message));

    this.loadHoTen();
    if(!Cache.getCache('SidebarToggled')){
      Cache.cache('SidebarToggled', false);
    }
    this.loadToggleSidebar();
  }

  loadHoTen()
  {
    let hoTen = Cache.getCache("HoTen");
    if(hoTen)
      this.userFullname = hoTen;
  }

  onReceiveCall(data: any) {
    if(data.type == "changeProfile")
    {
      this.loadHoTen();
    }
    if(data.type == "successChangeCustomer")
    {
      this.getDataCus();
    }
  }

  getDataCus()
  {
    let IDKhachHang = Cache.getCache("IDKhachHang");
    if(!IDKhachHang) return;
    this.authService.infoCustomer()
    .subscribe((res: any) => {
      if(res.Code == 1)
      {
        this.custName = res.Data?.Ten;
      }
    }, error => {
      console.log(error)
    });
  }

  loadToggleSidebar() {
    let sidebarToggled = Cache.getCache('SidebarToggled');
    this.iconOnlyToggled = sidebarToggled == 'true'? true : false;
    this.toggleSidebar();
  }

  toggleSidebar() {
    var sidebar = document.getElementById('main-container') as HTMLElement;
    var sidebar2 = document.getElementById('topbar_open') as HTMLElement;
    if(!this.iconOnlyToggled) {
      this.iconOnlyToggled = true;
      this.sidebarToggled = true;
      sidebar.classList.add('sidebar_minimize');
      sidebar2.classList.add('nav_open');
      Cache.cache('SidebarToggled', false);
    } else {
      this.iconOnlyToggled = false;
      this.sidebarToggled = false;
      sidebar.classList.remove('sidebar_minimize');
      sidebar2.classList.remove('nav_open');
      Cache.cache('SidebarToggled', true);
    }
  }

  toggleNarbar() {
    var sidebar2 = document.getElementById('topbar_open') as HTMLElement;
    if(!this.barToggled) {
      this.barToggled = true;
      sidebar2.classList.add('topbar_open');
    } else {
      this.barToggled = false;
      sidebar2.classList.remove('topbar_open');
    }
  }

  logout(): void {
    this.modal.confirm({
      nzTitle: this.translate.instant('auth.logout_confirm'),
      nzOkText: this.translate.instant('global.submit'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.submit(),
      nzCancelText: this.translate.instant('global.cancel'),
    });
  }
  submit(){
    Cache.deleteCacheUser();
    Cache.deleteCacheUrl();
    
    let data:any = {};
    data.type = "closeChangeCustomer";
    this.dataService.changeMessage(data);

    this.routerService.navigate(['auth/login'], {});
    //location.href = 'auth/login';
  }

  home()
  {
    this.routerService.navigate(['home'], {});
    //location.href = 'home';
  }
  
  info()
  {
    this.routerService.navigate(['profile'], {});
    //location.href = 'profile';
  }

  changePassword()
  {
    this.routerService.navigate(['profile/change-password'], {});
    //location.href = 'profile/change-password';
  }

  messager()
  {
    this.routerService.navigate(['manager/messagers'], {});
  }

  messagerDetail(id: string)
  {
    this.routerService.navigate(['manager/messagers'], {queryParams:{msgId: id}});
  }
  //change language
  changeLanguage(){
    let langCode = localStorage.getItem("lang");
    //let currentLang = document.getElementById("languageToggle") as HTMLAnchorElement;
    if (langCode === "vi"){
      // currentLang.classList.remove("langVN");
      // currentLang.classList.add("langEN");
      this.translate.use("en");
      localStorage.setItem("lang","en");
      this.i18n.setLocale(en_US);
    } else {
      // currentLang.classList.add("langVN");
      // currentLang.classList.remove("langEN");
      this.translate.use("vi");
      localStorage.setItem("lang","vi");
      this.i18n.setLocale(vi_VN);
    }
  }

  checkLang(){
    let langCode = localStorage.getItem("lang");
    //let currentLang = document.getElementById("languageToggle") as HTMLAnchorElement;
    if (langCode === "vi"){
      // currentLang.classList.remove("langEN");
      // currentLang.classList.add("langVN");
      this.translate.use("vi");
      this.i18n.setLocale(vi_VN);
    } else {
      // currentLang.classList.add("langEN");
      // currentLang.classList.remove("langVN");
      this.translate.use("en");
      this.i18n.setLocale(en_US);
    }
  }

  changeCustomer()
  {
    let data:any = {};
    data.type = "changeCustomer";
    this.dataService.changeMessage(data);
  }
  
  settingOpen()
  {
    var custom = document.getElementById('custom-template') as HTMLElement;
    custom.classList.add("open");
  }

  fullScreen() {
    this.openFullscreen();
    // this.zoom = !this.zoom;
    // if(this.zoom){
    //   this.openFullscreen();
    // }
    // else {
    //   this.closeFullscreen();
    // }
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
}
